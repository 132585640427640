import React from "react"
import { graphql } from "gatsby"
import useForm from "react-hook-form"
import Styled from "styled-components"

import Layout from "../../components/Layout"
import SEO from "../../components/Seo"
import RuledBreak from "../../components/RuledBreak"
import { ButtonTrace } from "../../components/Button"

import { URLS } from "../../config"

const Form = Styled.form`
	display: flex;
	flex-direction: column;
	justify-content: center;
	label, input, textarea {
		width: 100%;
    	box-sizing: border-box;
		display: flex;
		justify-content: center;
		background-color: ${({ theme }) => theme.input};;
		padding: 10px 15px;
		border: 0;
		color: ${({ theme }) => theme.onSurface};
		margin-bottom: 3px;
		font-family: ${({ theme }) => theme.fontSecondary};
		&:-webkit-autofill {
    		-webkit-box-shadow: ${({ theme }) => `0 0 0 100px ${theme.input} inset`};
			-webkit-text-fill-color: ${({ theme }) => theme.onSurface};
		}
	}
	input:focus, textarea:focus {
		outline: ${({ theme }) =>
      theme ? `${theme.primary} auto 5px` : "#000000 auto 5px"}
	}
	textarea {
		resize: none;
		min-height: 150px;
    	margin-bottom: 5px;
	}
	button {
		margin-top: 10px;
	}
	input, textarea {
		width: 100%;
	}
`
const Div = Styled.div`
	margin-top: -48px;
	height: 150px;
	background-color: ${({ theme }) => theme.onBackground};
	display: flex;
	justify-content: center;
	align-items: center;
	h2 {
		font-family: ${({ theme }) => theme.fontSecondary};
		color: ${({ theme }) => theme.secondary};
	}
`
const SVG = Styled.svg`
	path {
		stroke: ${({ theme }) => theme.onBackground};
		fill: ${({ theme }) => theme.onBackground};
	}
`

const Contact = ({ data }) => {
  const { register, handleSubmit, errors } = useForm()
  const onSubmit = async data => {
    try {
      const { name, email, message } = data
      console.log(name, email, message)
    } catch (error) {
      console.log(`CatchError: ${error}`)
    }
  }

  const headerSecondary = data.secondary.edges[0].node.childImageSharp.fluid
  return (
    <Layout img={headerSecondary}>
      <SEO title="Contact" keywords={["Body By Kickbush", "BBK"]} />
      <div className="row">
        <Div className="col-12">
          <h2>Have questions or wanna work together?</h2>
        </Div>
        <div className="col-12 pr-0 pl-0">
          <SVG
            preserveAspectRatio="none"
            viewBox="0 0 100 102"
            height="75"
            width="100%"
            version="1.1"
          >
            <path d="M0 0 L50 100 L100 0 Z"></path>
          </SVG>
        </div>
        <div className="col-12 py-4">
          <RuledBreak>Contact Me</RuledBreak>
        </div>
      </div>
      <div className="row">
        <div className="col-md-8 mr-auto ml-auto">
          <div className="container-fluid">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-12">
                  <input
                    type="text"
                    placeholder="Name"
                    name="name"
                    ref={register({
                      required: "Name is required",
                      maxLength: 100,
                    })}
                  />
                </div>
                <div className="col-12">
                  {errors.name && <p>{errors.name.message}</p>}
                </div>
              </div>
              {/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */}
              <div className="row">
                <div className="col-12">
                  <input
                    type="email"
                    name="email"
                    placeholder="Email"
                    ref={register({
                      required: "Email is required",
                      pattern: {
                        value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        message: "Invalid email address",
                      },
                    })}
                  />
                </div>
                <div className="col-12">
                  {errors.email && <p>{errors.email.message}</p>}
                </div>
              </div>
              {/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */}
              <div className="row">
                <div className="col-12">
                  <textarea
                    type="text"
                    name="message"
                    placeholder="Message"
                    ref={register({
                      required: "Message is required",
                    })}
                  />
                </div>
                <div className="col-12">
                  {errors.message && <p>{errors.message.message}</p>}
                </div>
              </div>
              <div className="row">
                <div className="col-12 text-center">
                  <ButtonTrace onClick={handleSubmit(onSubmit)} btn={3}>
                    Submit
                  </ButtonTrace>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query contactQuery {
    secondary: allFile(filter: { name: { eq: "kd_logo_white_600x600" } }) {
      edges {
        node {
          childImageSharp {
            fluid(quality: 100, maxWidth: 600) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
          id
        }
      }
    }
  }
`

export default Contact
